import React from 'react'

const style = {
    marginRight: '4px',
    background: '#f0f0f0',
    padding: '4px',
    borderBottomColor: 'transparent',
}

const Tag = ({tag}) => (
    <a href='#' style={style} onClick={e => e.preventDefault()}><span><small>{tag}</small></span></a>
)

export default Tag