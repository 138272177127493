import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Tag from "../components/tag"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { styled } from "@material-ui/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const styles = {
  "& .fab": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    position: "fixed",
    zIndex: 2,
    bottom: 24,
    right: 24,
    borderRadius: "50%",
    background: "white",
    boxShadow: "0 0 8px #DDD",
  },
}

const scrollToBottom = () => {
  window.scrollTo(0, document.scrollingElement.scrollHeight)
}

const getAvatarFixed = gatsbyImgObj =>
  (gatsbyImgObj &&
    gatsbyImgObj.childImageSharp &&
    gatsbyImgObj.childImageSharp.fixed) ||
  null

const BlogPostTemplate = ({
  data,
  pageContext,
  location,
  className,
  ...otherProps
}) => {
  const post = data.mdx
  const { frontmatter: { writer, writer_summary } = {} } = post
  const { previous: next, next: previous } = pageContext
  return (
    <Layout
      location={location}
      title={
        <div>
          <div style={{ fontSize: "50%" }}>back to</div>Home
        </div>
      }
      {...{ className }}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <noscript>
        <style>{`.fab {display: none !important}`}</style>
      </noscript>
      <button className="fab" onClick={scrollToBottom}>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <article>
        <header>
          <h1
            style={{
              marginBottom: rhythm(0.5),
            }}
          >
            {post.frontmatter.title}
          </h1>
          {post.frontmatter.description && (
            <p
              style={{
                ...scale(-1 / 5),
                marginBottom: rhythm(0.5),
              }}
            >
              {post.frontmatter.description}
            </p>
          )}
          {Array.isArray(post.frontmatter.tags) && (
            <p>
              {post.frontmatter.tags.map(c => (
                <Tag tag={c} key={c}></Tag>
              ))}
            </p>
          )}
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default styled(BlogPostTemplate)(styles)

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { regex: $slug }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
